@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "MuseoSansRounded", "sans-serif";
}

.custom-container {
  max-width: 700px;
}

.custom-bullets li {
  list-style-type: none;
  margin-left: 2.3em;
  text-indent: -0.4em;
}

.custom-bullets li:before {
  color: #cbc8c8;
  content: " »";
  font-size: 0.8rem;
  left: -1em;
  bottom: 0.1em;
  position: relative;
  vertical-align: middle;
}

.checkmarks li {
  list-style-image: url("./assets//img/checkmarks.svg");
}

.testimonial-text {
  font-size: 0.93rem;
}
